<template>
  <div
    style="width: 100%; height: 3rem; line-height: 3rem; display: flex;flex-direction: row;justify-content: space-between;">
    <div style="display: flex;">
      <div style="display: flex;" @click="selectCity">
        <span>{{ cityName }}</span>
        <span class="sanjiao"> </span>
      </div>
      <div style="display: flex;margin-left: 1rem;" @click="showThred">
        <span>{{ areaName }}</span>
        <span class="sanjiao"> </span>
      </div>
    </div>
    <div @click="search"><van-icon size="1.2rem" style="font-weight: 600;" name="search" /></div>
    <van-popup v-model="show" @closed="closed" position="bottom" :style="{ minHeight: '35%', }">
      <div style="display: flex;flex-direction: column;height: 100%;background-color: #EEE;">
        <div style="overflow-y: scroll; max-height: 18rem;padding-bottom: 0.5rem;">
          <div class="areaName" v-for="(item, index) in  areaList " @click="selectItem(item)">
            {{ item.regionName }} 
            <span style="margin-left: 0.5rem;"> ({{ item.cinemaNum }}) </span>
          </div>
        </div>
        <div class="width-100 btn" @click="closed" style="font-size: 1rem; background-color: white;">取消</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    filmId: '',
    type: 0
  },
  data() {
    return {
      cityName: '',
      cityCode: '',
      areaName: '',
      areaCode: '',
      show: false,
      areaList: [],
      regionId: '',
    }
  },
  created() { },
  components: {},
  methods: {
    search() {
      // this.$emit("searchCinemas");
      this.$router.push({
        path: "/CinemasList",
        query: {
          regionId: this.regionId,
          cityCode: this.cityCode,
          filmId: this.filmId,
          type: this.type
        },
      });
    },
    showThred() {
      this.show = true
    },
    closed() {
      this.show = false
    },
    selectCity() {
      this.$router.push({
        path: "/SelectAdderss",
        query: {
          type: 2,
        },
      });
    },
    setDate(listData) {
      this.areaList = listData
    },
    selectItem(val) {
      this.areaName = val.regionName
      this.areaCode = val.areaCode
      this.regionId = val.regionId
      this.$emit("areaCinemas", val.regionId);
      this.closed()
    },
    queryThirdAddress() {
      this.$api
        .queryThirdAddress(this.cityCode)
        .then((res) => {
          if (res.code == 0) {
            this.areaList = res.data
            // this.inseartAll()
          } else
            this.$Toast(res.msg)
        })
        .catch((res) => {

        });
    },

  },
  mounted() {
    this.cityCode = localStorage.getItem('cityCode')
    this.cityName = localStorage.getItem('address')
    this.areaName = localStorage.getItem('areaName') ? localStorage.getItem('areaName') : '全部'
    this.areaCode = localStorage.getItem('areaCode')
    if (this.type == 1) {
      this.queryThirdAddress()
    }

  },
}

</script>
<style  lang="scss" scoped>
.sanjiao {
  width: 0;
  height: 0;
  border: 0.3rem solid transparent;
  border-top: 0.3rem solid #000;
  margin-top: 1.3rem;
  margin-left: 0.2rem;

}

.areaName {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-bottom: 1px solid #CCC;
  background-color: white;
}

.btn {
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  text-align: center;
}
</style>